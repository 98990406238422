import React, {FC, useMemo, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {ImageSet, languageSelector} from '../../../../../types/types';
import {Link} from 'react-router-dom';
import {Routes} from '../../../../../routes/routes';
import {
    convertImagePathsToImageSet,
    getImageSet,
    getProductName,
    getProductSubTitle,
} from '../../../../../helper/helper';
import {Image as ImageDTO, ImageType, Product, ProductType} from '../../../../../types/products/types';
import CoverImage from '../../../../helper/CoverImage';
import {Dictionary} from '@reduxjs/toolkit';

import navBrasImageWebPLG2x from '../../../../../images/dest/navigation/nav_bras-lg@2x.webp';
import navBrasImageWebPLG from '../../../../../images/dest/navigation/nav_bras-lg@1x.webp';
import navBrasImageWebPMD2x from '../../../../../images/dest/navigation/nav_bras-md@2x.webp';
import navBrasImageWebPMD from '../../../../../images/dest/navigation/nav_bras-md@1x.webp';
import navBrasImageWebPSM2x from '../../../../../images/dest/navigation/nav_bras-sm@2x.webp';
import navBrasImageWebPSM from '../../../../../images/dest/navigation/nav_bras-sm@1x.webp';
import navBrasImageWebPXS2x from '../../../../../images/dest/navigation/nav_bras-xs@2x.webp';
import navBrasImageWebPXS from '../../../../../images/dest/navigation/nav_bras-xs@1x.webp';
import navBrasImageFallbackLG2x from '../../../../../images/dest/navigation/nav_bras-lg@2x.jpg';
import navBrasImageFallbackLG from '../../../../../images/dest/navigation/nav_bras-lg@1x.jpg';
import navBrasImageFallbackMD2x from '../../../../../images/dest/navigation/nav_bras-md@2x.jpg';
import navBrasImageFallbackMD from '../../../../../images/dest/navigation/nav_bras-md@1x.jpg';
import navBrasImageFallbackSM2x from '../../../../../images/dest/navigation/nav_bras-sm@2x.jpg';
import navBrasImageFallbackSM from '../../../../../images/dest/navigation/nav_bras-sm@1x.jpg';
import navBrasImageFallbackXS2x from '../../../../../images/dest/navigation/nav_bras-xs@2x.jpg';
import navBrasImageFallbackXS from '../../../../../images/dest/navigation/nav_bras-xs@1x.jpg';

import navPantiesImageWebPLG2x from '../../../../../images/dest/navigation/nav_panties-lg@2x.webp';
import navPantiesImageWebPLG from '../../../../../images/dest/navigation/nav_panties-lg@1x.webp';
import navPantiesImageWebPMD2x from '../../../../../images/dest/navigation/nav_panties-md@2x.webp';
import navPantiesImageWebPMD from '../../../../../images/dest/navigation/nav_panties-md@1x.webp';
import navPantiesImageWebPSM2x from '../../../../../images/dest/navigation/nav_panties-sm@2x.webp';
import navPantiesImageWebPSM from '../../../../../images/dest/navigation/nav_panties-sm@1x.webp';
import navPantiesImageWebPXS2x from '../../../../../images/dest/navigation/nav_panties-xs@2x.webp';
import navPantiesImageWebPXS from '../../../../../images/dest/navigation/nav_panties-xs@1x.webp';
import navPantiesImageFallbackLG2x from '../../../../../images/dest/navigation/nav_panties-lg@2x.jpg';
import navPantiesImageFallbackLG from '../../../../../images/dest/navigation/nav_panties-lg@1x.jpg';
import navPantiesImageFallbackMD2x from '../../../../../images/dest/navigation/nav_panties-md@2x.jpg';
import navPantiesImageFallbackMD from '../../../../../images/dest/navigation/nav_panties-md@1x.jpg';
import navPantiesImageFallbackSM2x from '../../../../../images/dest/navigation/nav_panties-sm@2x.jpg';
import navPantiesImageFallbackSM from '../../../../../images/dest/navigation/nav_panties-sm@1x.jpg';
import navPantiesImageFallbackXS2x from '../../../../../images/dest/navigation/nav_panties-xs@2x.jpg';
import navPantiesImageFallbackXS from '../../../../../images/dest/navigation/nav_panties-xs@1x.jpg';

import navSetsImageWebPLG2x from '../../../../../images/dest/navigation/nav_sets-lg@2x.webp';
import navSetsImageWebPLG from '../../../../../images/dest/navigation/nav_sets-lg@1x.webp';
import navSetsImageWebPMD2x from '../../../../../images/dest/navigation/nav_sets-md@2x.webp';
import navSetsImageWebPMD from '../../../../../images/dest/navigation/nav_sets-md@1x.webp';
import navSetsImageWebPSM2x from '../../../../../images/dest/navigation/nav_sets-sm@2x.webp';
import navSetsImageWebPSM from '../../../../../images/dest/navigation/nav_sets-sm@1x.webp';
import navSetsImageWebPXS2x from '../../../../../images/dest/navigation/nav_sets-xs@2x.webp';
import navSetsImageWebPXS from '../../../../../images/dest/navigation/nav_sets-xs@1x.webp';
import navSetsImageFallbackLG2x from '../../../../../images/dest/navigation/nav_sets-lg@2x.jpg';
import navSetsImageFallbackLG from '../../../../../images/dest/navigation/nav_sets-lg@1x.jpg';
import navSetsImageFallbackMD2x from '../../../../../images/dest/navigation/nav_sets-md@2x.jpg';
import navSetsImageFallbackMD from '../../../../../images/dest/navigation/nav_sets-md@1x.jpg';
import navSetsImageFallbackSM2x from '../../../../../images/dest/navigation/nav_sets-sm@2x.jpg';
import navSetsImageFallbackSM from '../../../../../images/dest/navigation/nav_sets-sm@1x.jpg';
import navSetsImageFallbackXS2x from '../../../../../images/dest/navigation/nav_sets-xs@2x.jpg';
import navSetsImageFallbackXS from '../../../../../images/dest/navigation/nav_sets-xs@1x.jpg';

import navAccessoriresImageWebPLG2x from '../../../../../images/dest/navigation/nav_accessoires-lg@2x.webp';
import navAccessoriresImageWebPLG from '../../../../../images/dest/navigation/nav_accessoires-lg@1x.webp';
import navAccessoriresImageWebPMD2x from '../../../../../images/dest/navigation/nav_accessoires-md@2x.webp';
import navAccessoriresImageWebPMD from '../../../../../images/dest/navigation/nav_accessoires-md@1x.webp';
import navAccessoriresImageWebPSM2x from '../../../../../images/dest/navigation/nav_accessoires-sm@2x.webp';
import navAccessoriresImageWebPSM from '../../../../../images/dest/navigation/nav_accessoires-sm@1x.webp';
import navAccessoriresImageWebPXS2x from '../../../../../images/dest/navigation/nav_accessoires-xs@2x.webp';
import navAccessoriresImageWebPXS from '../../../../../images/dest/navigation/nav_accessoires-xs@1x.webp';
import navAccessoriresImageFallbackLG2x from '../../../../../images/dest/navigation/nav_accessoires-lg@2x.jpg';
import navAccessoriresImageFallbackLG from '../../../../../images/dest/navigation/nav_accessoires-lg@1x.jpg';
import navAccessoriresImageFallbackMD2x from '../../../../../images/dest/navigation/nav_accessoires-md@2x.jpg';
import navAccessoriresImageFallbackMD from '../../../../../images/dest/navigation/nav_accessoires-md@1x.jpg';
import navAccessoriresImageFallbackSM2x from '../../../../../images/dest/navigation/nav_accessoires-sm@2x.jpg';
import navAccessoriresImageFallbackSM from '../../../../../images/dest/navigation/nav_accessoires-sm@1x.jpg';
import navAccessoriresImageFallbackXS2x from '../../../../../images/dest/navigation/nav_accessoires-xs@2x.jpg';
import navAccessoriresImageFallbackXS from '../../../../../images/dest/navigation/nav_accessoires-xs@1x.jpg';


import Image from '../../../../helper/Image';
import {useLocalization} from '../../../../../localization/localization';
import {makeStyles} from 'tss-react/mui';
import {Typography} from '@mui/material';
import ProductSubNavItem from './ProductSubNavItem';
import Button from '../../../../forms/buttons/Button';
import badge from '../../../../../images/icons/badge.svg';
import {productSelector} from '../../../../../reducer/products/types';

interface ProductSubNavProps {
    close: () => void;
    type: ProductType;
}

const useStyles = makeStyles<unknown>()((theme) => ({
    root: {
        width: 'auto',
        background: 'white',
        padding: theme.spacing(4),
        boxSizing: 'border-box',
        boxShadow: '0 8px 64px 0 rgba(0,0,0,0.08)'
    },
    contentSurround: {
        display: 'flex',
        padding: theme.spacing(0, 4, 0, 0)
    },
    productSurround: {
        display: 'flex',
        marginRight: 0
    },
    column: {
        marginRight: 60,
        [`& a:last-child`]: {
            marginBottom: 0
        }
    },
    icon: {
        width: 45,
        marginRight: theme.spacing(2),
        [theme.breakpoints.down('xl')]: {
            width: 30
        }
    },
    imageSurround: {
        width: 167,
        height: 203,
        overflow: 'hidden',
        position: 'relative',
        color: theme.palette.secondary.main,
        flexShrink: 0,
        borderRadius: 0,
        display: 'flex',
        alignItems: 'center'
    },
    staticImageSurround: {
        width: 167,
        height: 203,
        overflow: 'hidden',
        position: 'relative',
        flexShrink: 0,
        borderRadius: 0,
        marginLeft: theme.spacing(3)
    },
    link: {
        ...theme.link,
        position: 'absolute',
        left: '50%',
        bottom: '16px',
        transform: 'translate(-50%, 0)',
        display: 'block'
    },
    button: {
        minWidth: 'auto !important',
        whiteSpace: 'nowrap',
    },
    productSublineSurround: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        padding: theme.spacing(1),
        textAlign: 'center',
        fontWeight: 600,
        letterSpacing: 0.8,
        fontSize: 15,
        lineHeight: 1.25,
        color: theme.palette.secondary.main,
        background: 'rgba(255, 255, 255, 0.4)',
        textTransform: 'uppercase',
        whiteSpace: 'nowrap'
    },
    badge: {
        width: 163,
        height: 163
    }
}));


const ProductSubNav: FC<ProductSubNavProps> = ({close, type}) => {

    const {classes} = useStyles({});
    const [activeProduct, setActiveProduct] = useState<Product | null>(null);
    const [maxWidth, setMaxWidth] = useState<Dictionary<number>>({});
    const maxWidthRef = useRef<Dictionary<number>>({});
    const messages = useLocalization();

    const navBrasImageImageSet: ImageSet = useMemo<ImageSet>(() =>
        getImageSet(navBrasImageWebPXS, navBrasImageWebPXS2x, navBrasImageWebPSM, navBrasImageWebPSM2x, navBrasImageWebPMD, navBrasImageWebPMD2x,
            navBrasImageWebPLG, navBrasImageWebPLG2x, navBrasImageFallbackXS, navBrasImageFallbackXS2x, navBrasImageFallbackSM, navBrasImageFallbackSM2x,
            navBrasImageFallbackMD, navBrasImageFallbackMD2x, navBrasImageFallbackLG, navBrasImageFallbackLG2x), []);

    const navPantiesImageImageSet: ImageSet = useMemo<ImageSet>(() =>
        getImageSet(navPantiesImageWebPXS, navPantiesImageWebPXS2x, navPantiesImageWebPSM, navPantiesImageWebPSM2x, navPantiesImageWebPMD, navPantiesImageWebPMD2x,
            navPantiesImageWebPLG, navPantiesImageWebPLG2x, navPantiesImageFallbackXS, navPantiesImageFallbackXS2x, navPantiesImageFallbackSM, navPantiesImageFallbackSM2x,
            navPantiesImageFallbackMD, navPantiesImageFallbackMD2x, navPantiesImageFallbackLG, navPantiesImageFallbackLG2x), []);

    const navSetsImageImageSet: ImageSet = useMemo<ImageSet>(() =>
        getImageSet(navSetsImageWebPXS, navSetsImageWebPXS2x, navSetsImageWebPSM, navSetsImageWebPSM2x, navSetsImageWebPMD, navSetsImageWebPMD2x,
            navSetsImageWebPLG, navSetsImageWebPLG2x, navSetsImageFallbackXS, navSetsImageFallbackXS2x, navSetsImageFallbackSM, navSetsImageFallbackSM2x,
            navSetsImageFallbackMD, navSetsImageFallbackMD2x, navSetsImageFallbackLG, navSetsImageFallbackLG2x), []);

    const navAccessoriresImageImageSet: ImageSet = useMemo<ImageSet>(() =>
        getImageSet(navAccessoriresImageWebPXS, navAccessoriresImageWebPXS2x, navAccessoriresImageWebPSM, navAccessoriresImageWebPSM2x, navAccessoriresImageWebPMD, navAccessoriresImageWebPMD2x,
            navAccessoriresImageWebPLG, navAccessoriresImageWebPLG2x, navAccessoriresImageFallbackXS, navAccessoriresImageFallbackXS2x, navAccessoriresImageFallbackSM, navAccessoriresImageFallbackSM2x,
            navAccessoriresImageFallbackMD, navAccessoriresImageFallbackMD2x, navAccessoriresImageFallbackLG, navAccessoriresImageFallbackLG2x), []);


    const imageMap: { [key: number]: ImageSet } = {
        1: navBrasImageImageSet,
        2: navPantiesImageImageSet,
        3: navSetsImageImageSet,
        4: navAccessoriresImageImageSet
    }

    const labelMap: { [key: number]: string } = {
        1: messages.menu.subNav.allBras,
        2: messages.menu.subNav.allPanties,
        3: messages.menu.subNav.allSets,
        4: messages.menu.subNav.allAccessories
    }

    const products = useSelector(productSelector).filter(it => it.type.id === type.id);
    const language = useSelector(languageSelector);


    // Create arrays containing a maximum of 4 products for the menu
    const productMap: Map<number, Product[]> = new Map<number, Array<Product>>();
    let usedColumn = 0;
    products.sort((p1, p2) => p1.orderNumber - p2.orderNumber).forEach((product, index) => {
        // Maximum of 2 columns per product
        const threshold = products.length < 5 ? 5 : Math.ceil(products.length / 2);
        const column = usedColumn + (index < threshold ? 0 : 1);
        const existingProducts = productMap.get(column) || [];
        existingProducts.push(product);
        productMap.set(column, existingProducts);
    });


    const imageSet: ImageSet | null = useMemo<ImageSet | null>(() => {
        if (activeProduct) {
            const thumbnails: ImageDTO[] = activeProduct.images.filter(it => it.type === ImageType.THUMBNAIL);
            return convertImagePathsToImageSet(thumbnails[0]?.paths || []);
        }
        return null;
    }, [activeProduct]);


    return (
        <div className={classes.root}>
            <div className={classes.contentSurround}>
                <div className={classes.productSurround}>
                    {Array.from(productMap.keys()).map((key, index) => (
                        <div key={`product-sub-nav-column-${index}`} className={classes.column} style={{
                            width: maxWidth[index]
                        }}>
                            {productMap.get(key)?.map(product =>
                                <ProductSubNavItem product={product} maxWidthRef={maxWidthRef} setMaxWidth={setMaxWidth}
                                                   maxWidth={maxWidth} language={language}
                                                   setActiveProduct={setActiveProduct} index={index}
                                                   closeSubNav={close}/>)}
                        </div>
                    ))}
                </div>
                <div className={classes.imageSurround}>
                    {activeProduct && imageSet ?
                        <>
                            <CoverImage title={getProductName(activeProduct.translations, language)}
                                        alt={getProductName(activeProduct.translations, language)} imageSet={imageSet}
                                        equalWidthAndHeight={false} className={classes.imageSurround}/>
                            <Typography variant={'body1'} component={'div'} className={classes.productSublineSurround}>
                                {getProductSubTitle(activeProduct.translations, language)}
                            </Typography>
                        </>
                        : <img src={badge} alt={''} title={''} className={classes.badge}/>}
                </div>
                <div className={classes.staticImageSurround}>
                    <Image imageSet={imageMap[type.id]} alt={''} imageClass={''} title={''}/>
                    <Link 
                        to={Routes.Products.Overview(type.name)} 
                        className={classes.link} 
                        onClick={close}
                        style={{
                            fontFamily: 'Inter',
                            fontWeight: 500,
                            fontSize: '14px',
                            lineHeight: '100%',
                            letterSpacing: '0.75px',
                            textAlign: 'center',
                            textTransform: 'uppercase',
                            width: 137,
                            padding: '8px 0',
                            background: '#FFFFFF',
                            color: '#444444'
                        }}
                    >
                        {labelMap[type.id]}
                    </Link>
                </div>
            </div>
        </div>
    )

}

export default ProductSubNav;
