import {
    CartElement,
    CartState, checkKlarnaOrderAction,
    OrderResponseOrder,
    PaymentMethod,
    SetAddressPropertyPayload, UpdateAddressPayload, ValidateCartCouponSuccessPayload, ValidateCouponErrorPayload
} from './types';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {denormalize} from '../../helper/normalizeHelper';
import FetchStatus from '../../api/api';

const initialState: CartState = {
    elements: {},
    checkout: {
        useDeliveryAddressAsBillingAddress: true,
        billingAddress: {
            company: '',
            firstName: '',
            lastName: '',
            street: '',
            amendment: '',
            city: '',
            zipCode: '',
            email: '',
            country: -1
        },
        deliveryAddress: {
            company: '',
            firstName: '',
            lastName: '',
            street: '',
            amendment: '',
            city: '',
            zipCode: '',
            email: '',
            country: -1,
            phone: ''
        },
        completed: false,
        tracked: false,
        updatedBillingAddressProperties: [],
        updatedDeliveryAddressProperties: []
    },
    fetchStatus: FetchStatus.DEFAULT,
    confirmPaymentFetchStatus: FetchStatus.DEFAULT,
    klarnaFetchStatus: FetchStatus.DEFAULT,
    invoiceNumber: '',
    payPalOrderID: '',
    coupons: {
        fetchStatus: FetchStatus.DEFAULT,
        invalid: [],
        used: [],
        checkoutHasInvalidCoupons: false
    }
}

const cart = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addElementToCart: (state, {payload}: PayloadAction<CartElement>) => {
            let cartElement = denormalize(state.elements).find(it => it.productID === payload.productID && it.colorID === payload.colorID && it.sizeID === payload.sizeID);
            let id = cartElement?.id;
            if (!Boolean(cartElement)) {
                // If no cart element existed create a new one
                id = (Object.keys(state.elements).map(key => Number(key)).sort((i1, i2) => i2 - i1)[0] || 0) + 1;
                cartElement = payload;
                cartElement.id = id;
            } else {
                // Cart element existed, update the amount
                cartElement!.amount += payload.amount;
            }
            state.elements[id!] = cartElement;
            state.coupons = initialState.coupons;
        },
        decreaseElementToCart: (state, {payload}: PayloadAction<CartElement>) => {
            let cartElement = denormalize(state.elements).find(it => it.productID === payload.productID && it.colorID === payload.colorID && it.sizeID === payload.sizeID);
            let id = cartElement?.id;
            if (!Boolean(cartElement)) {
                // If no cart element existed create a new one
                id = (Object.keys(state.elements).map(key => Number(key)).sort((i1, i2) => i2 - i1)[0] || 0) + 1;
                cartElement = payload;
                cartElement.id = id;
            } else {
                // Cart element existed, update the amount
                cartElement!.amount -= payload.amount;
            }
            state.elements[id!] = cartElement;
            state.coupons = initialState.coupons;
        },
        removeElementFromCart: (state, {payload}: PayloadAction<number>) => {
            state.coupons = initialState.coupons;
            delete state.elements[payload];
        },
        setBillingAddressProperty: (state, {payload}: PayloadAction<SetAddressPropertyPayload<unknown>>) => {
            // @ts-ignore TODO Check if this can be better typed
            state.checkout.billingAddress[payload.property] = payload.value;
        },
        updateBillingAddress: (state, action: PayloadAction<UpdateAddressPayload>) => {
            state.checkout.billingAddress = {
                ...state.checkout.billingAddress,
                ...action.payload
            }
        },
        setDeliveryAddressProperty: (state, {payload}: PayloadAction<SetAddressPropertyPayload<unknown>>) => {
            // @ts-ignore TODO Check if this can be better typed
            state.checkout.deliveryAddress[payload.property] = payload.value;
        },
        updateDeliveryAddress: (state, action: PayloadAction<UpdateAddressPayload>) => {
            state.checkout.deliveryAddress = {
                ...state.checkout.deliveryAddress,
                ...action.payload
            }
        },
        setUseDeliveryAddressBillingAddress: (state, {payload}: PayloadAction<boolean>) => {
            state.checkout.useDeliveryAddressAsBillingAddress = payload;
            if (payload) {
                state.checkout.billingAddress = {
                    ...state.checkout.deliveryAddress,
                    email: state.checkout.billingAddress.email
                };
            } else {
                state.checkout.billingAddress = {
                    ...initialState.checkout.billingAddress,
                    email: state.checkout.billingAddress.email
                };
            }
        },
        resetDeliveryAddress: (state) => {
            state.checkout.deliveryAddress = {...initialState.checkout.deliveryAddress};
        },
        setPaymentMethod: (state, action: PayloadAction<PaymentMethod>) => {
            state.checkout.paymentMethod = action.payload
        },
        resetCheckout: state => {
            state.checkout = {...initialState.checkout};
        },
        resetCart: state => {
            return {...initialState};
        },
        fetchCheckout: state => {
            state.fetchStatus = FetchStatus.ACTIVE;
        },
        fetchCheckoutError: state => {
            state.fetchStatus = FetchStatus.ERROR;
        },
        fetchCheckoutSuccess: (state, action: PayloadAction<OrderResponseOrder>) => {
            state.fetchStatus = FetchStatus.SUCCESS;
            state.paymentResponse = action.payload.paymentResponse;
            // state.paymentUrl = action.payload.paymentUrl;
            state.invoiceNumber = action.payload.invoiceNumber;
        },
        resetCheckoutFetchStatus: (state) => {
            state.fetchStatus = FetchStatus.DEFAULT;
        },
        setCheckoutCompleted: state => {
            state.checkout.completed = true;
        },
        setCheckoutTracked: state => {
            state.checkout.completed = true;
            state.checkout.tracked = true;
        },
        setPayPalOrderID: (state, action: PayloadAction<string>) => {
            state.payPalOrderID = action.payload;
        },
        fetchValidateCoupon: state => {
            if (!Boolean(state.coupons)) {
                state.coupons = {};
            }
            state.coupons!.fetchStatus = FetchStatus.ACTIVE;
        },
        fetchValidateCouponError: (state, action: PayloadAction<ValidateCouponErrorPayload>) => {
            if (!Boolean(state.coupons)) {
                state.coupons = {};
            }
            state.coupons!.invalid = [...(state.coupons?.invalid || []), action.payload.coupon];
            state.coupons!.errorMessage = action.payload.message;
            state.coupons!.fetchStatus = FetchStatus.ERROR;
        },
        fetchValidateCouponSuccess: (state, action: PayloadAction<ValidateCartCouponSuccessPayload>) => {
            if (!Boolean(state.coupons)) {
                state.coupons = {};
            }
            if (action.payload.addToCart) {
                state.coupons!.used = [...(state.coupons?.used || []), action.payload];
                state.coupons!.fetchStatus = FetchStatus.SUCCESS;
                state.coupons!.errorMessage = '';
            }
        },
        resetValidateCouponFetchStatus: (state) => {
            if (!Boolean(state.coupons)) {
                state.coupons = {};
            }
            state.coupons!.fetchStatus = FetchStatus.SUCCESS;
        },
        resetValidateCouponErrorMessage: (state) => {
            if (!Boolean(state.coupons)) {
                state.coupons = {};
            }
            state.coupons!.errorMessage = '';
        },
        removeCoupon: (state, action: PayloadAction<string>) => {
            if (!Boolean(state.coupons)) {
                state.coupons = {};
            }
            state.coupons!.used = (state.coupons!.used || []).filter(it => it.code !== action.payload);
        },
        setUpdatedBillingAddressProperties: (state, action: PayloadAction<string[]>) => {
            state.checkout.updatedBillingAddressProperties = action.payload;
        },
        setUpdatedDeliveryAddressProperties: (state, action: PayloadAction<string[]>) => {
            state.checkout.updatedDeliveryAddressProperties = action.payload;
        },
        setCheckoutHasInvalidCoupons: (state, action: PayloadAction<boolean>) => {
            if (!Boolean(state.coupons)) {
                state.coupons = {};
            }
            state.coupons!.checkoutHasInvalidCoupons = action.payload;
            if (!action.payload) {
                // Reset the code from state
                state.coupons!.used = [];
            }
        }
    },
    extraReducers: builder => builder
        .addCase(checkKlarnaOrderAction.startAction, (state) => {
            state.klarnaFetchStatus = FetchStatus.ACTIVE;
        }).addCase(checkKlarnaOrderAction.successAction, (state) => {
            state.klarnaFetchStatus = FetchStatus.SUCCESS;
        }).addCase(checkKlarnaOrderAction.errorAction, (state) => {
            state.klarnaFetchStatus = FetchStatus.ERROR;
        })
});

export const {
    fetchCheckout,
    fetchCheckoutError,
    fetchCheckoutSuccess,
    resetCheckout,
    resetCart,
    setCheckoutTracked,
    setCheckoutCompleted,
    addElementToCart,
    decreaseElementToCart,
    removeElementFromCart,
    resetDeliveryAddress,
    setDeliveryAddressProperty,
    setUseDeliveryAddressBillingAddress,
    updateBillingAddress,
    updateDeliveryAddress,
    setUpdatedBillingAddressProperties,
    setUpdatedDeliveryAddressProperties,
    fetchValidateCoupon,
    fetchValidateCouponError,
    fetchValidateCouponSuccess,
    setBillingAddressProperty,
    resetValidateCouponFetchStatus,
    setPaymentMethod,
    removeCoupon,
    setPayPalOrderID,
    resetValidateCouponErrorMessage,
    resetCheckoutFetchStatus,
    setCheckoutHasInvalidCoupons
} = cart.actions;

export default cart.reducer;