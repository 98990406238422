import {ImageSet, ImageSize} from '../types/types';
import {createAction, Dictionary} from '@reduxjs/toolkit';
import {
    Country,
    CountryLocalizationKey,
    ImageFormat,
    ImagePath,
    Localization,
    LocalizationKey,
    Size
} from '../types/products/types';
import {EMAIL_REGEX, IMAGE_BASE_URL} from '../constants/constants';
import {Language} from '../reducer/application/types';
import {BlogPostImagePath} from '../reducer/admin/blog/types';
import {
    ButtonElementConfiguration,
    DeviceDimension,
    PageElementLocalization,
    Spacing,
    TextElementConfiguration
} from '../types/pages/types';
import {CSSProperties} from 'react';
import {notUndefined} from './normalizeHelper';


export const hexToRgba = (hex: string) => {

    let c = hex.startsWith('#') ? hex.substring(1) : hex;
    if (c.length === 3) {
        c = `${c.charAt(0)}${c.charAt(0)}${c.charAt(1)}${c.charAt(1)}${c.charAt(2)}${c.charAt(2)}`
    }
    const r = parseInt(c.slice(0, 2), 16),
        g = parseInt(c.slice(2, 4), 16),
        b = parseInt(c.slice(4, 6), 16);
    let a = 1;
    if (c.length === 8) {
        a = parseInt(c.slice(6, 8), 16) / 255
    }
    return {
        r,
        g,
        b,
        a
    }
}

export const rgba2hex = (r: number, g: number, b: number, alpha: number) => {
    let hex = (r | (1 << 8)).toString(16).slice(1) +
        (g | (1 << 8)).toString(16).slice(1) +
        (b | (1 << 8)).toString(16).slice(1);

    return hex + ((alpha * 255) | (1 << 8)).toString(16).slice(1);
}

export interface Rgba {
    r: number;
    g: number;
    b: number;
    a: number;
}

export const getRgbaAsString = (rgba: Rgba) => {
    return {
        r: isNaN(rgba.r) ? '' : rgba.r.toString(),
        g: isNaN(rgba.g) ? '' : rgba.g.toString(),
        b: isNaN(rgba.b) ? '' : rgba.b.toString(),
        a: isNaN(rgba.a) ? '' : rgba.a.toString()
    };

}

export const ThemeVariantMapping: Dictionary<CSSProperties> = {
    'h1': {
        fontFamily: 'Inter',
        fontSize: 80,
        fontWeight: 400,
        letterSpacing: -2,
        lineHeight: '1.25',
        textTransform: 'inherit',
        color: '#444444'
    },
    'h2': {
        fontFamily: 'PlayfairDisplay',
        fontSize: 48,
        fontWeight: 500,
        letterSpacing: -0.5,
        lineHeight: '1.35',
        textTransform: 'inherit',
        color: '#444444'
    },
    'h3': {
        fontFamily: 'Inter',
        fontSize: 32,
        fontWeight: 600,
        letterSpacing: -0.5,
        lineHeight: '1.2',
        color: '#444444'
    },
    'h4': {
        fontFamily: 'PlayfairDisplay',
        fontSize: 30,
        fontWeight: 600,
        letterSpacing: -1,
        lineHeight: '1.2',
        textTransform: 'inherit',
        color: '#444444'
    },
    'h5': {
        fontFamily: 'PlayfairDisplay',
        fontSize: 22,
        fontWeight: 600,
        letterSpacing: 0.15,
        lineHeight: '1.3',
        color: '#444444'
    },
    'h6': {
        fontFamily: 'Inter',
        fontSize: 18,
        fontWeight: 500,
        letterSpacing: -0.2,
        lineHeight: '1.25',
        textTransform: 'inherit',
        color: '#444444'
    },
    'subtitle1': {
        fontFamily: 'Inter',
        fontSize: 16,
        fontWeight: 600,
        letterSpacing: 0.15,
        lineHeight: '1.5',
        textTransform: 'inherit',
        color: '#444444'
    },
    'subtitle2': {
        fontFamily: 'PlayfairDisplay',
        fontSize: 16,
        fontWeight: 600,
        letterSpacing: 0.15,
        lineHeight: '1.5',
        textTransform: 'inherit',
        color: '#444444'
    },
    'body1': {
        fontFamily: 'Inter',
        fontSize: 16,
        fontWeight: 300,
        letterSpacing: -0.5,
        lineHeight: '1.5',
        textTransform: 'inherit',
        color: '#444444'
    },
    'body2': {
        fontFamily: 'Inter',
        fontSize: 14,
        fontWeight: 400,
        letterSpacing: 0.25,
        lineHeight: '1.6',
        textTransform: 'inherit',
        color: '#444444'
    },
    'button': {
        fontFamily: 'Inter',
        fontSize: 14,
        fontWeight: 400,
        letterSpacing: 1,
        lineHeight: '1.214',
        textTransform: 'uppercase'
    },
    'caption': {
        fontFamily: 'PlayfairDisplay',
        fontSize: 13,
        fontWeight: 400,
        letterSpacing: 0.4,
        lineHeight: '1.5',
        textTransform: 'inherit'
    },
    'overline': {
        fontFamily: 'Inter',
        fontSize: 10,
        fontWeight: 500,
        letterSpacing: 1.5,
        lineHeight: '1.6',
        textTransform: 'uppercase'
    }
}


export const getProductName = (localizations: Localization[], language: Language): string => localizations.filter(it => it.language === language && it.key === LocalizationKey.TITLE)[0]?.value || '';

export const getProductSubTitle = (localizations: Localization[], language: Language): string => localizations.filter(it => it.key === LocalizationKey.SUB_TITLE && it.language === language)[0]?.value || ''

export const convertImagePathsToImageSet = (paths: (ImagePath | BlogPostImagePath)[], baseUrl?: string): ImageSet => {
    const imageSet: ImageSet = new Map<ImageFormat, Map<ImageSize, string | undefined>>();
    paths.forEach(it => {
        let format = ImageFormat.FALLBACK;
        if (it.type.includes('svg')) {
            format = ImageFormat.SVG;
        }
        if (it.type.startsWith('video')) {
            format = it.type as ImageFormat;
        }
        if (it.type === 'image/webp') {
            format = ImageFormat.WEBP;
        }
        const sizes = imageSet.get(format) || new Map<ImageSize, string | undefined>();
        sizes.set(`${it.resolution}${it.retina ? '2x' : ''}` as ImageSize, `${baseUrl || IMAGE_BASE_URL}${it.path}`);
        imageSet.set(format, sizes);
    });
    return imageSet;
}

export const getImageSet = (imageWebPXS?: string, imageWebPXS2x?: string, imageWebPSM?: string, imageWebPSM2x?: string,
                            imageWebPMD?: string, imageWebPMD2x?: string, imageWebPLG?: string, imageWebPLG2x?: string,
                            imageFallbackXS?: string, imageFallbackXS2x?: string, imageFallbackSM?: string, imageFallbackSM2x?: string,
                            imageFallbackMD?: string, imageFallbackMD2x?: string, imageFallbackLG?: string, imageFallbackLG2x?: string): ImageSet => new Map<ImageFormat, Map<ImageSize, string | undefined>>([
    [ImageFormat.WEBP, new Map<ImageSize, string | undefined>([
        ['xs', imageWebPXS],
        ['xs2x', imageWebPXS2x],
        ['sm', imageWebPSM],
        ['sm2x', imageWebPSM2x],
        ['md', imageWebPMD],
        ['md2x', imageWebPMD2x],
        ['lg', imageWebPLG],
        ['lg2x', imageWebPLG2x]
    ])],
    [ImageFormat.FALLBACK, new Map<ImageSize, string | undefined>([
        ['xs', imageFallbackXS],
        ['xs2x', imageFallbackXS2x],
        ['sm', imageFallbackSM],
        ['sm2x', imageFallbackSM2x],
        ['md', imageFallbackMD],
        ['md2x', imageFallbackMD2x],
        ['lg', imageFallbackLG],
        ['lg2x', imageFallbackLG2x]
    ])]
]);

export const getBraAndPantiesSizes = (sizes: Size[]): { pantiesSizes: Size[], braSizes: Dictionary<Size[]> } => {
    const pantiesSizes: Size[] = [];
    const braSizes: Dictionary<Size[]> = {};
    sizes.forEach(it => {
        const number = parseInt(it.name, 10);
        if (isNaN(number) || it.name.includes('ml')) {
            pantiesSizes.push(it);
        } else {
            const char = it.name.split(' ')[1];
            if (char) {
                const array = braSizes[char] || [];
                array.push(it);
                braSizes[char] = array;
            }
        }
    });
    return {pantiesSizes, braSizes,};
}

export const validateEmail = (email: string) => EMAIL_REGEX.test(email.toLowerCase().trim());

export const getCountryName = (language: Language, country?: Country) => country?.translations?.find(localization => localization.language === language && localization.key === CountryLocalizationKey.NAME)?.value || country?.name || '';


export const createFetchAction = <Payload, FetchPayload, SuccessPayload, ErrorPayload, ResetActionPayload>(base: string, action: string) => {
    return {
        actionKey: `${base}/${action}`,
        action: createAction<Payload>(`${base}/${action}`),
        startAction: createAction<FetchPayload>(`${base}/fetch_${action}`),
        successAction: createAction<SuccessPayload>(`${base}/fetch_${action}_success`),
        errorAction: createAction<ErrorPayload>(`${base}/fetch_${action}_error`),
        resetAction: createAction<ResetActionPayload>(`${base}/fetch_${action}_reset`)
    }
}

export const getPageElementLocalization = (key: string, language: Language, localizations: PageElementLocalization<any>[], defaultValue: string = '') => {
    return localizations.find(it => it.key === key && it.language === language)?.value || defaultValue;
}


export const getPageElementStyle = (dimension: DeviceDimension, configuration?: TextElementConfiguration) => {
    const style: CSSProperties = {
        fontFamily: configuration?.fontFamily,
        fontWeight: configuration?.styles?.[dimension]?.fontWeight ?? configuration?.fontWeight,
        fontSize: configuration?.styles?.[dimension]?.fontSize ?? (dimension === DeviceDimension.Desktop && configuration?.fontSize ? configuration.fontSize : undefined),
        textAlign: configuration?.styles?.[dimension]?.textAlign || configuration?.textAlign,
        color: configuration?.color,
        lineHeight: configuration?.styles?.[dimension]?.lineHeight || (dimension === DeviceDimension.Desktop ? configuration?.lineHeight : undefined),
        letterSpacing: configuration?.styles?.[dimension]?.letterSpacing && !isNaN(parseFloat(configuration!.styles![dimension]!.letterSpacing!.toString())) ? parseFloat(configuration!.styles![dimension]!.letterSpacing!.toString()) : (dimension === DeviceDimension.Desktop && configuration?.letterSpacing && !isNaN(parseFloat(configuration.letterSpacing.toString())) ? parseFloat(configuration.letterSpacing.toString()) : undefined)
    }
    if (configuration?.variant) {
        const defaultStyling = ThemeVariantMapping[configuration!.variant];
        if (defaultStyling) {
            // Check if all keys in the style element are the same as in the default styling
            if (Object.keys(style).every(it => style[it as keyof CSSProperties] === defaultStyling[it as keyof CSSProperties])) {
                return {};
            }
        }
    }
    return style;
};

export const getRgbaString = (hex?: string) => {
    let rgba = undefined;
    if (hex) {
        const parsed = hexToRgba(hex);
        rgba = `rgba(${parsed.r},${parsed.g},${parsed.b},${parsed.a})`
    }
    return rgba;
}

const getParsedStyleValue = (value?: string | number) => value ? Number(value) : undefined;

export const getPageElementSpacing = (spacing?: Spacing,): CSSProperties => {
    let style: CSSProperties = {};
    if (spacing?.margin?.top !== undefined) {
        style.marginTop = getParsedStyleValue(spacing?.margin?.top);
    }
    if (spacing?.margin?.left !== undefined) {
        style.marginLeft = getParsedStyleValue(spacing?.margin?.left);
    }
    if (spacing?.margin?.right !== undefined) {
        style.marginRight = getParsedStyleValue(spacing?.margin?.right);
    }
    if (spacing?.margin?.bottom !== undefined) {
        style.marginBottom = getParsedStyleValue(spacing?.margin?.bottom);
    }
    if (spacing?.padding?.top !== undefined) {
        style.paddingTop = getParsedStyleValue(spacing?.padding?.top);
    }
    if (spacing?.padding?.left !== undefined) {
        style.paddingLeft = getParsedStyleValue(spacing?.padding?.left);
    }
    if (spacing?.padding?.right !== undefined) {
        style.paddingRight = getParsedStyleValue(spacing?.padding?.right);
    }
    if (spacing?.padding?.bottom !== undefined) {
        style.paddingBottom = getParsedStyleValue(spacing?.padding?.bottom);
    }

    return style;
}

export const getPageElementLocalizationWithFallbackLanguage = (localizations: PageElementLocalization<any>[] | undefined, key: string, language: Language, fallbackLanguages: Language[]) => {
    let localizationValue = localizations?.find(it => it.key === key && language === it.language)?.value;
    if (!localizationValue) {
        localizationValue = fallbackLanguages.map(it => localizations?.find(localization => localization.key === key && it === localization.language)?.value?.trim()).filter(notUndefined)[0]
    }
    return localizationValue;
}



export const checkIfLocalizationExists = (localizations: PageElementLocalization<any>[], textElement?: TextElementConfiguration | ButtonElementConfiguration) => {
    return Boolean(localizations.find(it => textElement && it.key === textElement?.localizationKey));
}