import React, {FC, useEffect, useRef, useState} from 'react';
import {useLocalization} from '../../../localization/localization';
import {Link, useLocation} from 'react-router-dom';
import ProductSubNav from './subNavs/products/ProductSubNav';
import {Routes} from '../../../routes/routes';
import {useDispatch, useSelector} from 'react-redux';
import {AppState, languageSelector} from '../../../types/types';
import {Dictionary} from '@reduxjs/toolkit';
import FindYourStyleSubNav from './subNavs/findYourStyle/FindYourStyleSubNav';
import PerfectYourSizeSubNav from './subNavs/perfectYourSize/PerfectYourSizeSubNav';
import {makeStyles} from 'tss-react/mui';
import {Fade, Tooltip, Typography} from '@mui/material';
import {LoadProductTypesActionAndSaga, productTypeSelector} from '../../../reducer/products/types';
import {useMenuColor} from '../../helper/useMenuColor';
import store from '../../../reducer/reducerManager';
import products from '../../../reducer/products/products';
import {useSagaSpawned} from '../../helper/hooks';
import productSaga from '../../../sagas/ProductSaga';

export interface MenuProps {
    preview?: boolean;
}

const useStyles = makeStyles<{ textColor?: string; hoverColor?: string; }, 'item'>()((theme, params, classes) => ({
    root: {
        marginBottom: theme.spacing(3),
        position: 'relative',
        zIndex: 100
    },
    contentSurround: {
        ...theme.body,
        maxWidth: 1000,
        display: 'flex',
        justifyContent: 'space-between',
        padding: 0
    },
    element: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        cursor: 'pointer',
        textDecoration: 'none',
        color: params.textColor || 'inherit',
        [`&:hover .${classes.item}`]: {
            color: params.hoverColor || '#000000',
            borderBottom: `1px solid ${params.hoverColor || '#000000'}`
        }
    },
    elementActive: {
        [`& .${classes.item}`]: {
            color: '#000000',
            borderBottom: '1px solid #000000'
        }
    },
    item: {
        letterSpacing: 0.5,
        fontSize: 15,
        lineHeight: '19px',
        color: theme.palette.secondary.main,
        textTransform: 'uppercase',
        cursor: 'pointer',
        transition: 'all 0.125s linear',
        paddingBottom: theme.spacing(1),
        borderBottom: '1px solid transparent'
    },
    activeItem: {
        [`& .${classes.item}`]: {
            color: params.hoverColor || '#000000',
            borderBottom: `1px solid ${params.hoverColor || '#000000'}`
        }
    },
    link: {
        display: 'block',
        color: 'inherit',
        textDecoration: 'none'
    },
    subNav: {
        width: 'auto',
        background: 'transparent',
        marginTop: theme.spacing(2)
    },
    tooltip: {
        background: 'transparent',
        width: 'auto',
        maxWidth: '100%',
        margin: 0,
        padding: 0,
        marginLeft: theme.spacing(-5)
    }
}));


store.reducerManager.add('products', products);

const Menu: FC<MenuProps> = ({preview = false}) => {

    const messages = useLocalization();
    const anchorElement = useRef<HTMLDivElement>(null);
    const {pathname} = useLocation();

    const dispatch = useDispatch();
    const productTypes = useSelector(productTypeSelector);
    const language = useSelector(languageSelector);
    const menuPreview = useSelector((state: AppState) => state.application.menu.preview ?? false);

    const {textColor, onMouseEnter, onMouseLeave, hoverColor} = useMenuColor();
    const {classes, cx} = useStyles({textColor, hoverColor});
    const [openSubNav, setOpenSubNav] = useState<Dictionary<boolean>>({});
    const isProductSagaSpawned = useSagaSpawned(productSaga);

    useEffect(() => {
        if (isProductSagaSpawned && !productTypes.length) {
            dispatch(LoadProductTypesActionAndSaga.action());
        }
    }, [dispatch, productTypes.length, isProductSagaSpawned]);

    return (
        <div className={classes.root} ref={anchorElement}>
            <div className={classes.contentSurround}>
                {productTypes.map((it) => {
                    return (
                        <Tooltip open={Boolean(openSubNav[it.id])}
                                 key={`product-type-${it.id}`} title={<ProductSubNav type={it} close={() => {
                            setOpenSubNav({...openSubNav, [it.id]: false});
                        }}/>} placement={'bottom-start'} classes={{
                            tooltip: classes.tooltip,
                            popper: classes.subNav
                        }} TransitionComponent={Fade} onClose={() => {
                            if (openSubNav[it.id]) {
                                setOpenSubNav({...openSubNav, [it.id]: false});
                            }
                        }} onOpen={() => {
                            setOpenSubNav({...openSubNav, [it.id]: true});
                        }}>
                            <Link to={Routes.Products.Overview(it.name)} className={cx(classes.element, {
                                [classes.activeItem]: openSubNav[it.id] || pathname.startsWith(Routes.Products.Overview(it.name))
                            })} onClick={() => {
                                setOpenSubNav({...openSubNav, [it.id]: false});
                            }}>
                                <Typography variant={'body1'} component={'span'} className={classes.item}
                                            style={{color: menuPreview === preview ? textColor : undefined}} onMouseEnter={onMouseEnter}
                                            onMouseLeave={onMouseLeave}>
                                    {it.translations.find(localization => localization.key === 'name' && localization.language === language)?.value || it.name}
                                </Typography>
                            </Link>
                        </Tooltip>
                    )
                })}
                <FindYourStyleSubNav>
                    <Link to={Routes.FindYourStyle.Home()} className={cx(classes.element, {
                        [classes.activeItem]: pathname.startsWith(Routes.FindYourStyle.Home())
                    })}>
                        <Typography variant={'body1'} component={'span'} className={classes.item}
                                    style={{color: menuPreview === preview ? textColor : undefined}} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                            {messages.menu.findYourStyle}
                        </Typography>
                    </Link>
                </FindYourStyleSubNav>
                <Link to={Routes.ColorYourDay()} className={cx(classes.element, {
                    [classes.activeItem]: pathname.startsWith(Routes.ColorYourDay())
                })}>
                    <Typography variant={'body1'} component={'span'} className={classes.item}
                                style={{color: menuPreview === preview ? textColor : undefined}} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                        {messages.menu.colorYourDay}
                    </Typography>
                </Link>
                <PerfectYourSizeSubNav>
                    <Link to={Routes.PerfectYourSize.Home('')} className={cx(classes.element, {
                        [classes.activeItem]: pathname.startsWith(Routes.PerfectYourSize.Home(''))
                    })}>
                        <Typography variant={'body1'} component={'span'} className={classes.item}
                                    style={{color: menuPreview === preview ? textColor : undefined}} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                            {messages.menu.fitting}
                        </Typography>
                    </Link>
                </PerfectYourSizeSubNav>
                {/* <Link to={Routes.BrandWorld} className={cx(classes.element, {
                    [classes.activeItem]: pathname.startsWith(Routes.BrandWorld)
                })}>
                    <Typography variant={'body1'} component={'span'} className={classes.item}
                                style={{color: menuPreview === preview ? textColor : undefined}} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                        {messages.menu.brandWorld}
                    </Typography>
                </Link> */}
                <Link to={Routes.Blog.Base('')} className={cx(classes.element, {
                    [classes.activeItem]: pathname.startsWith(Routes.Blog.Base(''))
                })}>
                    <Typography variant={'body1'} component={'span'} className={classes.item}
                                style={{color: menuPreview === preview ? textColor : undefined}}
                                onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                        {messages.menu.blog}
                    </Typography>
                </Link>
            </div>
        </div>
    )
}

export default Menu;
